

import __layout_0 from '/C:/Users/khatt/Downloads/hoppscotch/hoppscotch/packages/hoppscotch-common/src/layouts/default.vue'
export const layouts = {
'default': __layout_0,
'empty': () => import('/C:/Users/khatt/Downloads/hoppscotch/hoppscotch/packages/hoppscotch-common/src/layouts/empty.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
